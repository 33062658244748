import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/sections/hero"
import { attachImageEdgesRecursive } from "../utils/functions"
import Kontakt from "../components/Kontakt"

const index = {
	seo: {
		title: "Einfach Telefontermin buchen!",
		description:
			"Buchen Sie einen telefonischen Beratungstermin: ✔️ Wunschtermin angeben ✔️ unverbindlich ✔️ direkt mit dem GF",
	},
	hero: {
		h1: "Termin buchen - direkt mit unserem Geschäftsführer. Kostenfrei & unverbindlich!",
		checklist: [
			"Geben Sie Ihren Terminwunsch ein",
			"Wir sind auf Ihr Unternehmen vorbereitet",
			"Dauer des Telefonats ca. 15 – 30 Min.",
		],
		btnText: "Termin buchen",
		btnLink: "/termin/#termin",
		image: "termin_hero.jpg",
	},
}

const IndexPage = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					images: allFile {
						edges {
							node {
								relativePath
								childImageSharp {
									gatsbyImageData(placeholder: TRACED_SVG)
								}
							}
						}
					}
				}
			`}
			render={(data) => {
				attachImageEdgesRecursive(data.images.edges, index)

				return (
					<Layout>
						<Seo
							title={index.seo?.title}
							description={index.seo?.description}
						/>
						<Hero data={index.hero} />
						<div className="w-full bg-white" id="termin">
							<div className="sectionwidth sectionpadding">
								<Kontakt />
							</div>
						</div>
					</Layout>
				)
			}}
		/>
	)
}

export default IndexPage
