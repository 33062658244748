import React from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"

import { useState } from "react"
import { FormWrapper } from "./Forms"
import { useMediaQuery } from "react-responsive"

const Kontakt = ({ extraFields }) => {
	const isLaptop = useMediaQuery({ minWidth: 1280, maxWidth: 1535 })

	return (
		<div className="max-w-5xl mx-auto">
			<div className={"flex w-full flex-col lg:flex-row "}>
				<div className="flex-grow">
					<FormWrapper
						name="Termin buchen"
						subject="Termin-Anfrage - Internes-Recruiting.de"
						successMessage="Vielen Dank für Ihre Terminanfrage! Wir werden diese schnellstmöglich bestätigen. Schöne Grüße, Ihr Team von internes-recruiting.de"
					>
						<div className="grid grid-cols-1 gap-2 xl:grid-cols-2 2xl:grid-cols-1 2xl:gap-4">
							<div>
								<label htmlFor="name" className="block text-sm font-medium">
									Name*
								</label>
								<div className="mt-1">
									<input
										required
										type="text"
										name="Name"
										id="name"
										className="input"
									/>
								</div>
							</div>
							<div>
								<label htmlFor="email" className="block text-sm font-medium">
									Email*
								</label>
								<div className="mt-1">
									<input
										required
										type="email"
										name="Email"
										id="email"
										className="input"
									/>
								</div>
							</div>
							<div>
								<label htmlFor="name" className="block text-sm font-medium">
									Terminwunsch*
								</label>
								<div className="mt-1">
									<input
										required
										type="text"
										name="Terminwunsch"
										id="terminwunsch"
										className="input"
									/>
								</div>
							</div>
							<div>
								<label htmlFor="telefon" className="block text-sm font-medium">
									Telefon*
								</label>
								<div className="mt-1">
									<input
										required
										pattern="[0-9]*\/*(\+49)*[ ]*(\([0-9]+\))*([ ]*(-|–)*[ ]*[0-9]+)*"
										type="tel"
										name="Telefon"
										id="telefon"
										className="input"
									/>
								</div>
							</div>
							<label className="flex flex-col mt-2 mb-3 text-sm font-medium xl:col-span-2 2xl:col-span-1">
								Nachricht
								<textarea
									className="input"
									rows={isLaptop ? "3" : "6"}
									name="Nachricht"
								/>
							</label>
						</div>
					</FormWrapper>
				</div>
			</div>
		</div>
	)
}

export default Kontakt
